import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import { productObjOne } from "../components/Product/Data";
import Product from "../components/Product";
import Info from "../components/Info";
import { infoObjOne } from "../components/Info/Data";
import Services from "../components/Services";
import { servicesObjRow1 } from "../components/Services/Data";
import Expertise from "../components/Expertise";
import { ExpertiseObjRow1 } from "../components/Expertise/Data";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <Info {...infoObjOne} />
      <Product {...productObjOne} />
      <Services {...servicesObjRow1} />
      <Expertise {...ExpertiseObjRow1} />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
