export const productObjOne = {
  id: "products",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "FishEye Sotfware",
  headline: "Intelligent Fishing For Sustainable Resources",
  description:
    "Get access to our Software as a Service App for intelligent fishing. Get the fish distribution and cut-off the fishing addtional costs.",
  buttonLabel: "Let's talk",
  imgStart: false,
  img: require("../../images/svg-1.svg").default,
  alt: "Fisheye",
  dark: true,
  primary: false,
  darkText: true,
};
