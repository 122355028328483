import React from "react";
import styled from "styled-components";

const WrapperCompanyInfo = styled.div`
  background: #101522;
`;

const WrapperList = styled.ul`
  list-style: none;
  margin: 0 0 1rem 0;
  padding: 0;
  text-align: center;
  @media (min-width: 700px) {
    text-align: left;
  }
`;

const CompanyInfo = () => (
  <WrapperCompanyInfo>
    <WrapperList></WrapperList>
  </WrapperCompanyInfo>
);

export default CompanyInfo;
