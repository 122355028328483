// import React from 'react';
import styled from "styled-components";

const StyledButton = styled.button`
  background: #101522;
  border: 0;
  color: #fff;
  cursor: pointer;
  padding: 1em;
  text-transform: uppercase;
  width: 100%;

  &:hover,
  &:focus {
    background: #555d7b;
    color: #fff;
    outline: 0;
    transition: background-color 0.2s ease-out;
  }
`;

export default StyledButton;
