export const ExpertiseObjRow1 = {
  sectionTitle: "Our Expertise",
  icon1: require("../../images/cloud.svg").default,
  ExpertiseTitle1: "CLoud Technologies",
  ExpertiseDesc1: "",
  icon2: require("../../images/big_data.svg").default,
  ExpertiseTitle2: "Big Data & Analytics",
  ExpertiseDesc2: "",
  icon3: require("../../images/ml_ai.svg").default,
  ExpertiseTitle3: "Machine Learning & AI",
  ExpertiseDesc3: "",
  icon4: require("../../images/mmm.svg").default,
  ExpertiseTitle4: "Marketing Mix Modeling",
  ExpertiseDesc4: "",
  icon5: require("../../images/fisheries.svg").default,
  ExpertiseTitle5: "Fisheries Stock Assessment",
  ExpertiseDesc5: "",
  icon6: require("../../images/optimization.svg").default,
  ExpertiseTitle6: "Optimization & Automation",
  ExpertiseDesc6: "",
};
