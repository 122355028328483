import React, { Component } from "react";
//import all child components
import Container from "./ContactElements/Container";
import Title from "./ContactElements/Title";
import CompanyInfo from "./views/Form/CompanyInfo";
import ContactForm from "./views/Form/ContactForm";

class Contact extends Component {
  render() {
    return (
      <Container id="contact">
        <Title text="Contact Us" />
        <Container wrapper>
          <CompanyInfo />
          <ContactForm />
        </Container>
      </Container>
    );
  }
}

export default Contact;
