export const servicesObjRow1 = {
  sectionTitle: "Our Services",
  icon1: require("../../images/svg-2.svg").default,
  serviceTitle1: "Advanced technology",
  serviceDesc1:
    "Innovate your services and products with emerging technologies like AI, Machine Learning and Big Data.",
  icon2: require("../../images/svg-3.svg").default,
  serviceTitle2: "Software engineering",
  serviceDesc2:
    "Transform your ideas into tangible solutions that drive additional value and open new opportunities.",
  icon3: require("../../images/svg-4.svg").default,
  serviceTitle3: "Digital transformation",
  serviceDesc3:
    "Evaluate technology choices, optimize processes, and discover solutions for your business challenges.",
};
