import React from "react";

import {
  ProductContainer,
  ProductWrapper,
  ProductRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  VideoContainer,
  Video,
} from "./ProductElements";
import { Button } from "../ButtonElement";
const Product = ({
  id,
  lightBg,
  imgStart,
  topLine,
  headline,
  lightText,
  darkText,
  description,
  buttonLabel,
}) => {
  return (
    <ProductContainer id={id} lightBg={lightBg}>
      <ProductWrapper>
        <ProductRow imgStart={imgStart}>
          <Column1>
            <TextWrapper>
              <TopLine>{topLine}</TopLine>
              <Heading lightText={lightText}>{headline}</Heading>
              <Subtitle darkText={darkText}>{description}</Subtitle>
              <BtnWrap>
                <Button
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  lightBg={lightBg ? 1 : 0}
                >
                  {buttonLabel}
                </Button>
              </BtnWrap>
            </TextWrapper>
          </Column1>
          <Column2>
            <VideoContainer>
              <Video background={true} video="743366979" responsive />
            </VideoContainer>
          </Column2>
        </ProductRow>
      </ProductWrapper>
    </ProductContainer>
  );
};

export default Product;
