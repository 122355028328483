import React from "react";

import {
  ExpertiseContainer,
  ExpertiseH1,
  ExpertiseWrapper,
  ExpertiseCard,
  ExpertiseIcon,
  ExpertiseH2,
  ExpertiseP,
} from "./ExpertiseElements";

const Expertise = ({
  sectionTitle,
  icon1,
  ExpertiseTitle1,
  ExpertiseDesc1,
  icon2,
  ExpertiseTitle2,
  ExpertiseDesc2,
  icon3,
  ExpertiseTitle3,
  ExpertiseDesc3,
  icon4,
  ExpertiseTitle4,
  ExpertiseDesc4,
  icon5,
  ExpertiseTitle5,
  ExpertiseDesc5,
  icon6,
  ExpertiseTitle6,
  ExpertiseDesc6,
}) => {
  return (
    <ExpertiseContainer id="expertise">
      <ExpertiseH1>{sectionTitle}</ExpertiseH1>
      <ExpertiseWrapper>
        <ExpertiseCard>
          <ExpertiseIcon src={icon1} />
          <ExpertiseH2>{ExpertiseTitle1}</ExpertiseH2>
          <ExpertiseP>{ExpertiseDesc1}</ExpertiseP>
        </ExpertiseCard>
        <ExpertiseCard>
          <ExpertiseIcon src={icon2} />
          <ExpertiseH2>{ExpertiseTitle2}</ExpertiseH2>
          <ExpertiseP>{ExpertiseDesc2}</ExpertiseP>
        </ExpertiseCard>
        <ExpertiseCard>
          <ExpertiseIcon src={icon3} />
          <ExpertiseH2>{ExpertiseTitle3}</ExpertiseH2>
          <ExpertiseP>{ExpertiseDesc3}</ExpertiseP>
        </ExpertiseCard>
        <ExpertiseCard>
          <ExpertiseIcon src={icon4} />
          <ExpertiseH2>{ExpertiseTitle4}</ExpertiseH2>
          <ExpertiseP>{ExpertiseDesc4}</ExpertiseP>
        </ExpertiseCard>
        <ExpertiseCard>
          <ExpertiseIcon src={icon5} />
          <ExpertiseH2>{ExpertiseTitle5}</ExpertiseH2>
          <ExpertiseP>{ExpertiseDesc5}</ExpertiseP>
        </ExpertiseCard>
        <ExpertiseCard>
          <ExpertiseIcon src={icon6} />
          <ExpertiseH2>{ExpertiseTitle6}</ExpertiseH2>
          <ExpertiseP>{ExpertiseDesc6}</ExpertiseP>
        </ExpertiseCard>
      </ExpertiseWrapper>
    </ExpertiseContainer>
  );
};

export default Expertise;
