import React from "react";
import {
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterWrap,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Casablanca</FooterLinkTitle>
              <p>265, Bd Zerktouni, N°92 - Casablanca</p>
              <br />
              <p>+212 614 592 144</p>
              <br />
              <p>info@intelligentica.net</p>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle></FooterLinkTitle>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle></FooterLinkTitle>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle></FooterLinkTitle>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
              <FooterLink to="#"></FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">Intelligentica</SocialLogo>
            <WebsiteRights>
              Intelligentica (c) {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://twitter.com/intelligentica/"
                target="_blank"
                aria-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/channel/UCdrawIZQ0nbeg0xycxA_VUA"
                target="_blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.linkedin.com/company/intelligentica/"
                target="_blank"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink
                href="https://github.com/INTELLIGENTICA-SARL"
                target="_blank"
                aria-label="Github"
              >
                <FaGithub />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.facebook.com/intelligentica"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
