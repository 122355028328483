import React from "react";

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = ({
  sectionTitle,
  icon1,
  serviceTitle1,
  serviceDesc1,
  icon2,
  serviceTitle2,
  serviceDesc2,
  icon3,
  serviceTitle3,
  serviceDesc3,
}) => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>{sectionTitle}</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={icon1} />
          <ServicesH2>{serviceTitle1}</ServicesH2>
          <ServicesP>{serviceDesc1}</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={icon2} />
          <ServicesH2>{serviceTitle2}</ServicesH2>
          <ServicesP>{serviceDesc2}</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={icon3} />
          <ServicesH2>{serviceTitle3}</ServicesH2>
          <ServicesP>{serviceDesc3}</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
