import React from "react";
import styled from "styled-components";
import { css } from "styled-components";

//import components
import Form from "../../ContactElements/Form";
import Label from "../../ContactElements/Label";
import StyledButton from "../../ContactElements/Button";
import Input from "../../ContactElements/Input";
import Textarea from "../../ContactElements/Textarea";
import emailjs from "emailjs-com";

const WrapperGrid = styled.div`
  ${(props) =>
    props.full &&
    css`
      grid-column: 1 / 3;
    `}
`;

const ContactForm = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_daz8s7l",
        "template_ehm3vj6",
        e.target,
        "9EEEi4fViX1uNEpBJ"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
    e.target.reset();
  }

  return (
    <div>
      <Form onSubmit={sendEmail}>
        <WrapperGrid>
          <Label>Name</Label>
          <Input type="text" name="name" />
        </WrapperGrid>
        <WrapperGrid>
          <Label>Company</Label>
          <Input type="text" name="company" />
        </WrapperGrid>
        <WrapperGrid>
          <Label>Email Address</Label>
          <Input type="email" name="email" />
        </WrapperGrid>
        <WrapperGrid>
          <Label>Phone Number</Label>
          <Input type="text" name="phone" />
        </WrapperGrid>
        <WrapperGrid full>
          <Label>Message</Label>
          <Textarea name="message" rows="5"></Textarea>
        </WrapperGrid>
        <WrapperGrid full>
          <StyledButton>Submit</StyledButton>
        </WrapperGrid>
      </Form>
    </div>
  );
};

export default ContactForm;
