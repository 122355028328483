import React from "react";
import { inrh, iccat, ciek, dgmare, pds } from "./Data";
import "./brand.css";

const Brand = () => (
  <div className="gpt3__brand section__padding">
    <div>
      <img src={inrh} alt="INRH" />
    </div>
    <div>
      <img src={iccat} alt="ICCAT" />
    </div>
    <div>
      <img src={ciek} alt="CIEK" />
    </div>
    <div>
      <img src={dgmare} alt="DGMARE" />
    </div>
    <div>
      <img src={pds} alt="PDS" />
    </div>
  </div>
);

export default Brand;
