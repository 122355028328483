export const infoObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Intelligentica",
  headline: "Who we are",
  description:
    "Intelligentica is a friendly, flexible and forward thinking startup. We are leveraging the power of the new technologies such AI, Machine Learning and Big Data to deliver innovative solutions. By delivering value to our partners and clients with the power of technology, we inspire confidence that their digital initiatives will succeed. They trust us with finding solutions to the toughest challenges and breaking constraints on their way to meaningful innovation in a dynamic market environment. We streamline your business processes through bespoke software, databases, mobile & web apps. And, with interactive dashboards and analytics, your data can be explored to unlock the value to support business decision making.",
  buttonLabel: "Let's talk",
  imgStart: true,
  img: require("../../images/svg-1.svg").default,
  alt: "Fisheye",
  dark: true,
  primary: true,
  darkText: true,
};
